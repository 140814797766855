<template>
  <v-app>
  <v-container fill-height>
    <v-row justify="center">
      <v-card width="95%" class="mx-auto">
        <v-card-title class="justify-center">
          {{ status }}
        </v-card-title>

         <v-card-text class="text-center">
          {{ message }}
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-icon
            x-large
            color="orange">
            mdi-emoticon-sick-outline
          </v-icon>
        </v-card-actions>
        <v-card-actions class="justify-center">
          <v-btn
            icon
            x-large
            color="primary"
            @click="redirect"
          >
            <v-icon>
              mdi-home
            </v-icon>
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-row>
  </v-container>
  </v-app>
</template>

<script>
export default {
  layout: 'none',

  // 追加
  computed: {
    status () {
      return "アクセスできません。"
    },
    message() {
      return this.$route.params.message
    }
  },

  methods: {
    redirect () {
      return this.$route.name === 'index'
        ? this.$router.go() : this.$router.push('/')
    }
  }
}
</script>
